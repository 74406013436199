<script>
import omit from 'lodash.omit'
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import { getAction } from '@/command/netTool'
import DetailFormGroup from '../../../components/DetailFormGroup'
const getChildren = function getChildren(children) {
  return children.map(e => {
    return {
      ...e,
      label: e.name,
      value: e.code,
      children: e.children && e.children.length > 0 ? getChildren(e.children) : null
    }
  })
}
export default {
  name: 'hospitalDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {
        regionCode: [],
        villageCode: [],
        address: '',
        lat: '',
        lon: ''
      },
      regionCode: [],
      villageCodeArr: [],
      jwdAyy: []
    }
  },
  mounted() {
    const { id } = this.$route.query
    getAction('/config/point/queryPointList', {
      type: 3
    }).then(res => {
      if (res.code == 200) {
        this.jwdAyy = res.data.map(e => {
          if (id == e.id) {
            let icon = new AMap.Icon({
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
              imageSize: new AMap.Size(20, 40)
            })
            return {
              lng: e.longitude,
              lat: e.latitude,
              icon: icon,
              id: e.id
            }
          } else {
            return {
              lng: e.longitude,
              lat: e.latitude,
              id: e.id
            }
          }
        })
      } else {
        this.$message.error(res.msg)
      }
    })

    // 获取级联数据
    api.command.getRegionType.call(this, {
      url: '/api/region/threeTree',
      key: 'regionCode'
    })

    if (id) {
      api.command.getDetail
        .call(this, {
          url: `/config/farmHospital/get?id=${id}`
        })
        .then(result => {
          this.handleGetZC(result.areaCode)
          this.detail.regionCode = [result.provinceCode, result.cityCode, result.areaCode]
          this.detail.villageCode = [result.townCode, result.villageCode]
          this.detail.dw = `${result.lon}，${result.lat}`
          this.$forceUpdate()
        })
    }
  },
  methods: {
    handleGetZC(ata) {
      getAction('/sysArea/listAll?areaCode=' + ata, {}, '/dpi').then(res => {
        if (res.code == 200) {
          this.villageCodeArr = getChildren(res.data)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    onOpenMap() {
      apiTool.openMap({
        title: '打开地图',
        value: this.jwdAyy,
        id: this.$route.query.id,
        onSuccess: data => {
          this.detail.lat = data.lat
          this.detail.lon = data.lng
          this.detail.dw = `${this.detail.lon}，${this.detail.lat}`
          let index = this.jwdAyy.findIndex(e => e.id == data.id)
          let icon = new AMap.Icon({
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png',
            imageSize: new AMap.Size(20, 40)
          })
          let obj = {
            lng: data.lng,
            lat: data.lat,
            icon: icon,
            id: data.id
          }
          if (index > -1) {
            this.jwdAyy.splice(index, 1, obj)
          } else {
            this.jwdAyy.push(obj)
          }
          this.$forceUpdate()
        }
      })
    },
    getForm1() {
      return {
        title: '位置',
        type: 'cardForm',
        data: [
          {
            name: '经纬度 （例：120.184349，30.254460）',
            type: 'row',
            cols: 12,
            rules: [
              {
                required: true
              }
            ],
            gutter: 5,
            children: [
              {
                type: 'input',
                cols: 21,
                key: 'dw',
                label: '经纬度',
                placeholder: '请输入经纬度以中文逗号隔开'
              },
              {
                type: 'button',
                label: '定位',
                cols: 3,
                key: 'a1',
                props: {
                  type: 'primary',
                  icon: 'environment',
                  on: {
                    click: this.onOpenMap
                  }
                }
              }
            ]
          },
          {
            name: '地址',
            type: 'row',
            cols: 12,
            colsType: {
              lg: 24,
              md: 24,
              sm: 24,
              xs: 24,
              xxl: 12,
              xl: 18
            },
            gutter: 5,
            rules: [
              {
                required: true
              }
            ],
            children: [
              {
                type: 'cascader',
                cols: 10,
                key: 'regionCode',
                typeData: this.regionCode,
                label: '地址',
                rules: [
                  {
                    required: true,
                    type: 'array',
                    message: '请选择地址'
                  }
                ],
                onChange: this.handleAreaCode
              },
              {
                type: 'input',
                cols: 14,
                key: 'address',
                label: '地址'
              }
            ]
          },
          {
            name: '名称',
            type: 'input',
            key: 'name',
            cols: 12,
            rules: [
              {
                required: true
              }
            ]
          }
        ]
      }
    },
    getForm2() {
      return {
        title: '村庄关联',
        type: 'cardForm',
        data: [
          {
            type: 'cascader',
            cols: 12,
            key: 'villageCode',
            typeData: this.villageCodeArr,
            label: '地址',
            placeholder: '请选择地址',
            rules: [
              {
                required: true,
                type: 'array',
                message: '请选择地址'
              }
            ]
          }
        ]
      }
    },
    handleAreaCode(data) {
      this.handleGetZC(data[2])
    },
    getSumbitData() {
      const codeText = ['province', 'city', 'area']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[0].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = this.detail.regionCode[i]
      })
      return obj
    },
    getSumbitData2() {
      const codeText = ['town', 'village']
      let arr = document.getElementsByClassName('ant-cascader-picker-label')[1].innerHTML.split('/')
      const obj = {}
      arr.forEach((e, i) => {
        obj[codeText[i] + 'Name'] = e
        obj[codeText[i] + 'Code'] = this.detail.villageCode[i]
      })
      return obj
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            const codeData = this.getSumbitData()
            const codeData2 = this.getSumbitData2()
            if (data.villageCode instanceof Array && data.villageCode.length == 1) {
              this.detail.villageCode = data.villageCode.toString()
            }
            let arr = data.dw.split('，')
            data.lon = arr[0]
            data.lat = arr[1]
            let obj = {
              ...data,
              ...codeData,
              ...codeData2,
              regions: `${codeData.provinceName + codeData.cityName + codeData.areaName}`
            }
            api.command[this.detail.id ? 'editPost' : 'create']
              .call(this, {
                url: `/config/farmHospital/${this.detail.id ? 'update' : 'add'}`,
                params: {
                  ...obj
                },
                isPost: false
              })
              .then(() => {
                this.$router.back()
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.back()
        }
      ]
      return {
        left
      }
    }
  },
  render() {
    return <DetailFormGroup form={this.detail} foot={this.getFoot()} data={[this.getForm1(), this.getForm2()]} />
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
